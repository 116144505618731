<div class="flex">
  <div class="filter-container">
    <p-calendar placeholder="Data" dateFormat="dd/mm/yy" [showClear]="true" (onSelect)="filter($event)"
      (onClear)="resetta($event)"></p-calendar>

    <p-dropdown optionLabel="descrizione" [options]="descrizioni" optionValue="descrizione" [filter]="true"
      filterBy="descrizione" [showClear]="true" (onChange)="filterDescrizione($event.value)" placeholder="Descrizione">
    </p-dropdown>

    <p-dropdown [options]="email" optionLabel="email" optionValue="email" [filter]="true" filterBy="email"
      [showClear]="true" placeholder="Email" (onChange)="filterEmail($event.value)">
    </p-dropdown>
  </div>

  <button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
    pButton label="SCADENZA" icon="pi pi-plus" style="height: 2.25em;" (click)="showDialog()"></button>
</div>

<p-dialog *ngIf="visible" header="Scadenza" [(visible)]="visible" [style]="{width: '60vw'}"
  [contentStyle]="{'overflow':'visible'}" [modal]="true" [draggable]="false">
  <app-cup-promemoria-form [scadenza]="scadenza" [isInEdit]="isInEdit" [visible]="visible"
    (closeDialogPromemoria)="onCloseScadenzaForm($event)" [permesso]="permesso" [user]="user"></app-cup-promemoria-form>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<p-toast></p-toast>
<p-card>
  <p-table [value]="filteredTab" [(selection)]="selectedScadenza" dataKey="" [paginator]="true" [rows]="5"
    [showCurrentPageReport]="true" currentPageReportTemplate="Da {first} a {last} di {totalRecords} promemoria"
    [rowsPerPageOptions]="[5, 10, 25, 50]" [(selection)]="selectedScadenza" dataKey="id">

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5em"><p-tableHeaderCheckbox (click)="selectedRow(undefined, true)"></p-tableHeaderCheckbox>
        </th>
        <th>Creata il</th>
        <th>Scadenza</th>
        <th>Descrizione</th>
        <!-- <th>Alert</th> -->
        <th>Email</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-scadenza>
      <tr [pSelectableRow]="scadenza">
        <td style="width: 5%;">
          <p-tableCheckbox [value]="scadenza" (click)="selectedRow(scadenza.id)"></p-tableCheckbox>
        </td>
        <td style="width: 10%;">{{ scadenza.createdAt | date:"dd/MM/yy"}}</td>
        <td style="width: 10%;">{{ scadenza.data | date:"dd/MM/yy"}}</td>
        <td style="width: 30%;">{{(scadenza.descrizione.length>6)? (scadenza.descrizione |
          slice:0:100)+'...':(scadenza.descrizione) }}</td>
        <!-- <td><div [innerHTML]="replaceCommaWithNewLine(scadenza.alertAt, true)"></div></td> -->
        <td style="width: 15%;">
          <div [innerHTML]="replaceCommaWithNewLine(scadenza.emails, false)"></div>
        </td>
        <td style="width: 10%;">
          <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
            type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialog(scadenza)"></p-button>
          <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
            type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="confirm(scadenza)"></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!-- </div> -->
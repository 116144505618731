<div class="container p-3 mt-3 ">
  <p-card>
    <form [formGroup]="loginForm">
      <div class="containerLoginForm">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="username" class="col-form-label">Username</label>
            <input formControlName="username" id="username" type="text" autocomplete="name" class="form-control" placeholder="Username">
            <!-- <small style="color: red" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
              Inserisci il numero</small> -->
          </div>

          <div class="form-group col-md-12">
            <label for="password" class="col-form-label">Password</label>
            <input formControlName="password" type="password" id="password" autocomplete="current-password"  class="form-control" placeholder="Password">
            <!-- <small *ngIf="!loginForm.get('data').valid && loginForm.get('data').touched">
              Inserisci una data</small> -->
          </div>


        </div>

        <p-toast></p-toast>
        <div class="footer_button" style="display: flex; gap: 10px;">
          <button pButton label="ACCEDI" class="p-button-success" [disabled]="loginForm.invalid" (click)="accedi()"></button>
          <button pButton label="RECUPERA PASSWORD" class="p-button-plain" [disabled]="loginForm.controls.username.invalid" (click)="recuperaPassword=true"></button>
        </div>
      </div>

    </form>
  </p-card>


  <p-dialog header="Recupera password" [(visible)]="recuperaPassword" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <app-reset-password></app-reset-password>
  </p-dialog>

  <p-dialog header="Seleziona Ente" [(visible)]="clientsVisible" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false" [resizable]="false">
    <div class="m-2 row" >
      <button class="col-12" pButton *ngFor="let client of clients" [label]="client" (click)="retryLoginWithClientId(client)">
      </button>
    </div>
  </p-dialog>

</div>

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { EntiService, Comune } from 'src/app/service/file';
import { Login, LoginResponse } from 'src/app/model/utente';
import { UserClientService } from 'src/app/service/user-client.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class LoginComponent {

  loginForm: FormGroup = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
  });

  user: Login;
  comuni: Comune[];
  clientsVisible: boolean = false;
  recuperaPassword: boolean = false;
  filteredComuni: Comune[] | undefined;
  clients: string[] = [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entiServices: EntiService,
    private spinner: NgxSpinnerService,
    private userClientService: UserClientService,
    private messageService: MessageService,
  ) {  }


  ngOnInit() {
    this.entiServices.getComuni().then((comuni) => {
      this.comuni = comuni;
    });
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });

  }

  accedi(clientId?: string) {
    console.log(clientId);
    this.clientsVisible = false;
    this.clients = []
    let login: Login = {
      password: this.loginForm.value.password,
      ente: clientId,
      userName: this.loginForm.value.username
    }
    console.log("---- spinner accedi login ----")
    this.spinner.show()
    this.userClientService.login(login).subscribe({
      next: (resp: HttpResponse<LoginResponse>) => {
        if (!resp.body.accessToken) {
          this.spinner.hide();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Autenticazione non riuscita' });
        } else {
          this.spinner.hide();
          console.log(resp.body?.role);
          this.userClientService.saveToken(resp.body); 
          localStorage.setItem('infoUtente', login.ente);
          localStorage.setItem('infoRuolo', resp.body?.role);
          this.router.navigate(['/']);
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 409) {
          console.log(err);
          this.clients = err.error;
          this.clientsVisible = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Autenticazione non riuscita' });
        }
      }
    })

  }

  retryLoginWithClientId(clientId: string) {
    this.accedi(clientId)
  }

}

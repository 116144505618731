<p-tabView [activeIndex]="activeIndex" styleClass="mainMenu" class="mainMenu" (onChange)="handleChange($event)">

  <!--Cup-->
  <p-tabPanel [disabled]="permessi?.permessoCup === TipoPermesso.None" header="CUP">
    <p-tabView [activeIndex]="0" class="smallMenu">

      <!--Dettaglio CUP-->
      <p-tabPanel header="Dettaglio CUP">
        <p-toast></p-toast>
        <p-card id="noPaddingCard">

          <div *ngIf="!datiModelloDocumentale?.formGroup" class="text-center">
            <p-progressSpinner></p-progressSpinner>
            <div> Caricamento Cup in corso </div>
          </div>

          <div style="position: relative; min-height: 55vh;">
            <upload-file *ngIf="datiModelloDocumentale?.formGroup" nomeModello="CUP" [header]="'CUP '+ cup?.codice" [cup]="cup" [isCupInitialized]="isCupInitialized"
              [datiModelloDocumentale]="datiModelloDocumentale" [buttonAnnulla]="false" [isScenarioCup]="true" [permesso]="permessi?.permessoCup" [user]="user"
              (save)="save($event)" (delete)="cancellazioneCup()" (close)="fineUpload($event)" (onRefreshCup)="onRefreshCup($event)"></upload-file>
          </div>
        </p-card>

        <p-dialog *ngIf="showDeletedCupDialog" header="Eliminazione CUP" [(visible)]="showDeletedCupDialog"
          [style]="{width: '50vw'}" [contentStyle]="{'overflow':'visible'}" [modal]="true" [draggable]="false">
          <div>Se elimini il CUP perderai tutti gli allegati contenuti. <br />Sei davvero certo di eliminare questo Cup?
          </div>

          <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" (click)="showDeletedCupDialog = false" label="No, torna indietro"
              class="p-button-text"></button>
            <button pButton pRipple icon="pi pi-check" (click)="deleteCup()" label="Si, elimina"
              class="p-button-danger p-button-text"></button>
          </ng-template>
        </p-dialog>

      </p-tabPanel>

      <!--Gestione Utenti-->
      <p-tabPanel *ngIf="isUtenteAbilitato"
        header="Gestione Utenti">
        <app-gestione-utenti-form [idCup]="idCup"></app-gestione-utenti-form>
      </p-tabPanel>

    </p-tabView>
  </p-tabPanel>

  <!--Quadro economico-->
  <p-tabPanel [disabled]="permessi?.permessoQE === TipoPermesso.None" header="Quadro Economico">
    <div *ngIf="!cup" class="text-center">
      <p-progressSpinner></p-progressSpinner>
      <div> Caricamento Quadro Economico in corso </div>
    </div>
    <app-cup-qe *ngIf="cup" [cup]="cup" [refreshRowsQE]="refreshRowsQE" (refreshCup)="refreshCup()" 
      [user]="user" [permesso]="permessi?.permessoQE"></app-cup-qe>
    <!-- isnewQE-->
  </p-tabPanel>

  <!--Esecuzione-->
  <p-tabPanel header="Esecuzione" [disabled]="!idCup || !qe || permessi?.permessoEsecuzione === TipoPermesso.None" styleClass="no-margin-top">
    <div *ngIf="!qe" class="text-center">
      <p-progressSpinner></p-progressSpinner>
      <div> Caricamento Esecuzione in corso </div>
    </div>
    <app-cup-esecuzione *ngIf="idCup && qe && cup" [qe]="qe" [idCup]="idCup" [isEsecuzioneEnable]="isEsecuzioneEnable"
      [cup]="cup" (sendRowImpegnate)="onReceiveRows($event)" [user]="user" [permesso]="permessi?.permessoEsecuzione"></app-cup-esecuzione>
  </p-tabPanel>

  <!--Documentazione-->
  <p-tabPanel [disabled]="permessi?.permessoDocumenti === TipoPermesso.None" header="Documenti">
    <rbk-documenti [isId]="isId" [cupId]="idCup" [isRefreshTab]="isRefreshTab" [nomeTab]="'Documenti'"
      [cup]="cup" (onRefreshTabChange)="onRefreshTab($event)" [user]="user" [permesso]="permessi?.permessoDocumenti"></rbk-documenti>
  </p-tabPanel>

  <!--Fonti di Finanziamento-->
  <p-tabPanel [disabled]="permessi?.permessoFonti === TipoPermesso.None" header="Fonti di finanziamento">
    <app-fonti *ngIf="idCup" [cupId]="idCup" [cup]="cup" [user]="user" [permesso]="permessi?.permessoFonti"></app-fonti>
  </p-tabPanel>

  <!--Promemoria-->
  <p-tabPanel [disabled]="permessi?.permessoPromemoria === TipoPermesso.None" header="Promemoria">
    <app-cup-promemoria *ngIf="idCup" [cupId]="idCup" [user]="user" [permesso]="permessi?.permessoPromemoria"></app-cup-promemoria>
  </p-tabPanel>

  <!--Annotazioni-->
  <p-tabPanel [disabled]="permessi?.permessoAnnotazioni === TipoPermesso.None" header="Annotazioni"> 
    <app-cup-annotazioni *ngIf="idCup" [cupId]="idCup" [user]="user" [permesso]="permessi?.permessoAnnotazioni"></app-cup-annotazioni>
    <!-- <p-tabView [activeIndex]="0" class="smallMenu">
      <p-tabPanel header="Annotazioni">
        <app-cup-annotazioni *ngIf="idCup" [cupId]="idCup"></app-cup-annotazioni>
      </p-tabPanel>

      <p-tabPanel header="Documenti annotazioni">
        <rbk-documenti [isId]="isId" [cupId]="idCup" nomeTab="Annotazioni" [cup]="cup"></rbk-documenti>
      </p-tabPanel>
    </p-tabView> -->
  </p-tabPanel>

</p-tabView>
import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Stato } from '../../model/stato';
import { Scadenza } from 'src/app/model/scadenza';
import { ScadenzeService } from 'src/app/service/scadenze.service';
import { ConfirmationService, Message, MessageService, PrimeNGConfig } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/service/shared.service';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';


@Component({
	selector: 'app-cup-promemoria',
	templateUrl: './cup-promemoria.component.html',
	styleUrls: ['./cup-promemoria.component.scss'],
	providers: [ConfirmationService, MessageService]
})


export class CupPromemoriaComponent {

	@Input() cupId: number;
	/**
	 * Permesso in base all'utente loggato
	 */
	@Input() public permesso: TipoPermesso;
	/**
   * Utente loggato
   */
	@Input() public user: Utente;

	stati: Stato[] = [
		{ stato: 'tutti' },
		{ stato: 'attivo' },
		{ stato: 'in programmazione' },
		{ stato: 'chiuso' },
	];

	descrizioni = []
	email = []

	//filterForm: FormGroup;
	scadenza: Scadenza;
	scadenze: Scadenza[]
	filteredTab: Scadenza[]
	selectedScadenza: Scadenza[];

	isRowSelected: boolean = false;
	showMessages: boolean;
	visible: boolean;
	isId: boolean;
	id: number;
	isInEdit: boolean;

	public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private scadenzeServices: ScadenzeService,
		private confirmationService: ConfirmationService,
		private primengConfig: PrimeNGConfig,
		private messageService: MessageService,
		private spinner: NgxSpinnerService,
		private sharedServices: SharedService,
	) {
		this.showMessages = false;

		this.cupId = this.sharedServices.getIdCup()
		this.scadenza = this.sharedServices.getScadenza()
	}


	async ngOnInit() {
		this.primengConfig.ripple = true;
		//this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
		//this.cupId = Number(this.route.snapshot.paramMap.get('id'));

		if (this.cupId) {

			this.scadenze = await this.scadenzeServices.getScadenzeByCup(this.cupId).toPromise();
			this.filteredTab = this.scadenze;

			for (let promemoria of this.filteredTab) {
				promemoria.data = new Date(`${promemoria.data.toString()}`);
				this.descrizioni.push({ "descrizione": promemoria.descrizione });

				for (let mail of promemoria.emails) {
					this.email.push({ "email": mail });
				}
			}
			this.removeDuplicates(this.email);
		}

		if (this.scadenza) {
			this.showDialog(this.scadenza);
		}

	}

	removeDuplicates(mail: string[]) {

		let unique = [];
		mail.forEach(element => {
			if (!unique.includes(JSON.stringify(element))) {
				unique.push(JSON.stringify(element));
			}
		});

		this.email = []
		for (let elem of unique) {
			this.email.push(JSON.parse(elem));
		}

		return this.email;
	}


	showDialog(scadenza?: Scadenza) {
		this.visible = true;
		this.scadenza = scadenza;
		this.isInEdit = !!scadenza;
	}

	confirm(scadenza) {
		this.confirmationService.confirm({
			message: "Sei sicuro di voler eliminare questa scadenza?",
			header: "Eliminazione",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Si, elimina",
			rejectLabel: "No, torna indietro",
			acceptButtonStyleClass: "p-button-danger p-button-text",
			rejectButtonStyleClass: "p-button-text",
			accept: () => {
				this.messageService.add({
					severity: "info",
					summary: "Confirmed",
					detail: "Promemoria eliminato con successo",
				});
				this.scadenzeServices.deleteScadenza(scadenza.id).subscribe({
					next: resp => {
						this.scadenza = resp;
						console.log(resp);
						this.onUpdatepromemoria();
					}
				})
			},
		});
	}

	selectedRow(id?: number, bool?: boolean) {
		console.log(this.selectedScadenza)
		for (let elem of this.selectedScadenza) {
			elem.visualizza = true;
		}
		this.sharedServices.visualizzaScadenze(this.selectedScadenza);
		/* if(id){
			console.log(this.filteredTab.find(x => x.id === id))
			this.scadenza = this.filteredTab.find(x => x.id === id);
			this.scadenza.visualizza = !this.scadenza.visualizza;
			console.log(this.scadenza.visualizza)

		}
		if(bool){
			console.log(this.filteredTab)
			for(let elem of this.filteredTab){
				elem.visualizza = !elem.visualizza;
			}
		} */
	}

	replaceCommaWithNewLine(values, bool: boolean): string {
		let result = "";
		if (bool) {
			for (let date of values.sort(function (a, b) { return a - b })) {
				if (date === 1) {
					result += "<span class='detailDays'>" + date + " giorno</span> <br/> "
				} else {
					result += "<span class='detailDays'>" + date + " giorni</span> <br/> "
				}
			}
		} else {
			values.forEach(x => {
				result += "<span class='chip-mail'>" + x + "</span>"
			})
		}
		return result;
	}

	filterDescrizione(event) {
		this.filteredTab = this.scadenze;
		console.log(event)
		if (event) {
			this.filteredTab = this.filteredTab.filter(x => x.descrizione === event)
		} else {
			this.filteredTab = this.scadenze;
		}

		/* console.log("DAL DB SCADENZA: ", this.filteredTab[0].data);
		this.filteredTab = this.filteredTab.filter(x=> x.data > event); */
	}


	filterEmail(event) {
		this.filteredTab = this.scadenze;
		console.log(event)
		if (event) {
			this.filteredTab.filter(x => x.emails.forEach(element => {
				if (element === event) {
					this.filteredTab = this.filteredTab.filter(x => x.emails.includes(event))
				}
			}))
		} else {
			this.filteredTab = this.scadenze;
		}
	}

	resetta(event: any) {
		this.filteredTab = this.scadenze;
	}

	filter(event) {
		console.log(event)
		this.filteredTab = this.scadenze;
		if (event) {
			this.filteredTab = this.filteredTab.filter(x => x.data > event);
		} else {
			this.filteredTab = this.scadenze;
		}
	}

	onCloseScadenzaForm(bool) {
		this.visible = false;
		if (bool) {
			this.onUpdatepromemoria();
		}
	}

	onUpdatepromemoria() {
		this.scadenzeServices.getScadenzeByCup(this.cupId).subscribe({
			next: (resp: Scadenza[]) => {
				if (resp) {
					this.scadenze = resp;
					this.filteredTab = this.scadenze;
					this.descrizioni = []
					this.email = [];
					for (let promemoria of this.filteredTab) {
						this.descrizioni.push({ "descrizione": promemoria.descrizione });
						for (let mail of promemoria.emails) {
							this.email.push({ "email": mail });
						}
					}
					this.removeDuplicates(this.email);
				}
				this.spinner.hide();
			},
			error: (err) => {
				console.log(err);
				this.spinner.hide();
			}
		})

		/* this.filterForm = this.fb.group({
			descrizione: new FormControl(1),
			email: new FormControl(1),
			data: new FormControl(1),
		}); */
	}

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { UserClientService } from 'src/app/service/user-client.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})


export class ResetPasswordComponent implements OnInit {
  resetPwdForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private userClientService: UserClientService,
    private router: Router,
  ){
    this.resetPwdForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.resetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPwdForm.invalid) {
      return;
    }
    this.loading = true;
    this.spinner.show();
    this.userClientService.resetPass(this.f.username.value).subscribe(
      res => {

        this.messageService.add({
          severity: "info",
          summary: "Reset Password",
          detail: "Se l'utente esiste, riceverai una email per il reset della password",
          sticky: true
        });
        console.log(res);
setTimeout(() => {

        this.spinner.hide();
}, 500);
        this.loading = false;
        this.router.navigate(['/login']);
      },
      err => {

        this.messageService.add({
          severity: "error",
          summary: "Reset Password",
          detail: "Errore: " + err
        });
        console.log(err);
setTimeout(() => {

        this.spinner.hide();
}, 500);
        this.loading = false;
        this.router.navigate(['/login']);
      }
    );
  }

}

<header>
  <div class="bg-white">
    <nav
      class="container box-shadow navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom mb-2 justify-content-between">
      <a style="width: 33%; text-align: left;" [routerLink]="['/']">
        <img src="assets/image/MONITORAnext-187x40.png"
          alt="MONITORAnext - Monitoraggio e controllo delle Opere Pubbliche">

      </a>

      <div class="info-comune" [title]="infoUtente">{{infoUtente}}</div>

      <div *ngIf="utente" class="navbar-collapse collapse d-sm-inline-flex justify-content-end" style="width: 33%">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li>
          <li *ngIf="isUserSuperAdmin" class="nav-item"
            [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/ente']">Enti</a>
          </li>
          <li *ngIf="isUserAdmin || isUserSuperAdmin" class="nav-item dropdown" style="height: 2.25em;">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span class="pi pi-cog"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="nav-link text-dark" [routerLink]="['/configurazione']">
                  Dizionari
                </a></li>
              <li><a class="nav-link text-dark" [routerLink]="['/modelliDocumetali']">
                  Modelli Documentali
                </a></li>
            </ul>
          </li>
          <li *ngIf="isUserAdmin" class="nav-item" >
            <a class="nav-link text-dark" (click)="showDialogEnte()">Utenti</a>
          </li>
          <li class="nav-item dropdown mr-2" style="height: 2.25em;">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span class="pi pi-user"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li> <a class="nav-link text-dark" [routerLink]="['/formUtente']">
                  <small>{{utente.userName}}, {{utente.clientId}}</small></a>
              </li>
              <li><a class="nav-link text-dark" href="/assets/Uploader/Uploader.zip">Scarica Uploader</a></li>
              <li><a class="nav-link text-dark" [routerLink]="['/changePassword']">Cambia Password</a></li>
              <li><a class="nav-link text-danger" [routerLink]="['/login']" (click)="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
        <button pButton *ngIf="isUserAdmin || isUserSuperAdmin" icon="pi pi-upload" (click)="lavoraDocumentiSospesi()" class="mr-2"
          style="background-color: #35b6c7; border-color: #35b6c7" pTooltip="Documenti archiviati"></button>

      </div>
    </nav>
  </div>


</header>

<p-dialog *ngIf="visible" header="{{nomeEnte}}" [(visible)]="visible" [style]="{width: '75vw'}" [modal]="true"
    [draggable]="false">
    <app-ente [enteDto]="ente" [isInEdit]="true" [visible]="visible" [isAdmin]="true"
      (closeDialog)="onClose($event)"></app-ente>
  </p-dialog>
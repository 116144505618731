import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { UserClientService } from 'src/app/service/user-client.service';


export function passWord(p: FormControl): { [s: string]: boolean } {
  if (!p.value) {
    return { invalidPass: true };
  }
  return null
}

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {

  conifrmResetPwdForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  code: string = '';
  email: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private userClientService: UserClientService,
    private router: Router,
  ) {
    this.conifrmResetPwdForm = this.formBuilder.group({
      newpassword: ['', [passWord, Validators.required]],
      confirmnewpassword: ['', [passWord, Validators.required]]

    });

    route.queryParams.subscribe(
      params => {
        this.code = params['code'];
        this.email = params['email'];
      });

  }

  ngOnInit() {

  }

  get f() { return this.conifrmResetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.conifrmResetPwdForm.invalid) {
      return;
    }
    this.loading = true;
    this.spinner.show()
    this.userClientService.confirmResetPass(this.email,this.f.newpassword.value, this.code).subscribe(
      res => {
setTimeout(() => {

        this.spinner.hide();
}, 500);;
        this.loading = false;
        this.messageService.add({
          severity: "info",
          summary: "Reset Password",
          detail: "Password correttamente modificata",
          sticky: true
        });
        this.router.navigate(['/login']);
      },
      err => {
        this.messageService.add({
          severity: "error",
          summary: "Reset Password",
          detail: "Errore: " + err
        });
setTimeout(() => {

        this.spinner.hide();
}, 500);
        this.loading = false;
      });
  }

}
